/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react"
import PropTypes from "prop-types"
import qs from "query-string"
import { Form, Header, Message, Container } from "semantic-ui-react"
import Layout from "@src/components/layout"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const errorMessage = "An error occurred - please try again: [error]"

class ContactLowCodeForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      submitting: false,
      success: false,
      error: false,
      name: "",
      nameError: false,
      email: "",
      emailError: false,
      company: "",
      companyError: false,
      content: "",
      contentError: false,
      phone: "",
      website: "",
    }

    this.formName = "contact-low-code"

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value, [`${name}Error`]: false })
  }

  handleSubmit(e) {
    e.preventDefault()

    const { name, email, content } = this.state

    let errored = false
    if (!name) {
      this.setState({ nameError: true })
      errored = true
    }

    if (!email) {
      this.setState({ emailError: true })
      errored = true
    }

    if (!content) {
      this.setState({ contentError: true })
      errored = true
    }

    if (errored) {
      this.setState({ errored: true })
      return
    }

    this.setState({ submitting: true })

    const submission = { "form-name": this.formName, ...this.state }
    Object.keys(submission).forEach(key => {
      if (
        ["name", "email", "phone", "content", "form-name"].indexOf(key) === -1
      ) {
        delete submission[key]
      }
    })

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: qs.stringify(submission),
    })
      .then(() => {
        window.location.href = "/thank-you-for-contacting-us"
      })
      .catch(error =>
        this.setState({
          submitting: false,
          success: false,
          error: error.message,
        })
      )
  }

  render() {
    const {
      success,
      error,
      name,
      nameError,
      email,
      emailError,
      phone,
      content,
      contentError,
      submitting,
    } = this.state

    return (
      <Form
        onSubmit={this.handleSubmit}
        name={this.formName}
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        error={error}
        success={success}
        loading={submitting}
        style={{ margin: "6em auto 0 auto" }}
      >
        <input type="hidden" name="form-name" value={this.formName} />
        <input type="hidden" name="bot-field" />

        <Form.Input
          fluid
          name="name"
          placeholder="Name*"
          value={name}
          onChange={this.handleChange}
          error={nameError ? "Please enter your name." : false}
        />

        <Form.Input
          fluid
          name="email"
          type="email"
          placeholder="Email*"
          value={email}
          onChange={this.handleChange}
          error={emailError ? "Please enter your email address." : false}
        />

        <Form.Input
          fluid
          name="phone"
          placeholder="Contact Number (optional)"
          type="tel"
          value={phone}
          onChange={this.handleChange}
        />

        <Form.TextArea
          name="content"
          placeholder="Anything else you wish to tell us? (Optional)"
          onChange={this.handleChange}
          error={contentError ? "Please enter your message." : false}
          value={content}
        />

        <div data-netlify-recaptcha="true" />

        <Message error>{errorMessage.replace("[error]", error)}</Message>

        <div style={{ marginTop: "4em", textAlign: "right" }}>
          <Form.Button basic type="submit">
            Submit
          </Form.Button>
        </div>
      </Form>
    )
  }
}

const ContactLowCodeContent = () => {
  const data = useStaticQuery(graphql`
    query LowCodeApplicationsQuery {
      file(relativePath: { eq: "low-code-applications-fast.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  `)

  return (
    <>
      <Header
        as="h1"
        style={{
          color: "#424874",
        }}
      >
        Apply now for your Low-Code Proof of Concept
      </Header>
      <p>
        Low-Code empowers you and your teams to quickly build, deploy and use an
        application without writing lines of new code. Development in the
        Low-Code environment is done visually. So, instead of writing code and
        logic you build the UI and create flows of logic all within the
        platform.
      </p>

      <GatsbyImage
        image={data.file.childImageSharp.gatsbyImageData}
        style={{ maxWidth: "600px", margin: "0 auto" }}
        alt=""
      />
      <h2>Low-Code enables your business to:</h2>
      <strong>Bring your Ideas to Market 10 Time Faster</strong>
      <p>
        Capture new markets, create new services, and captivate customers in new
        and unimagined ways, by freeing your technical team from legacy systems
        and software development delays.
      </p>
      <strong>Refine and Optimise your Customer Experience</strong>
      <p>
        Launch new customer experiences that leapfrog your competitors and grow
        your business.
      </p>
      <strong>
        Transform productivity by Informing your Business Management Team
      </strong>
      <p>
        Streamline operations to better serve your customers and increase
        efficiency. With limited free prototype opportunities available.
      </p>
      <strong>Fuel Innovation for the Future</strong>
      <p>
        Drive real growth and make an impact. Launch brilliant new customer
        experiences, prototype and deploy new ideas in weeks, and leap ahead to
        lead your market.
      </p>
      <strong>Build for the Future</strong>
      <p>
        Build future-proof applications that leverage information from current
        systems to business critical insights, that won’t incur technical debt
        empowering Low-Code and Unipro to drive the evolution of your tech stack
        so you can focus on your business.
      </p>
      <strong>Modernise Your IT Stack</strong>
      <p>
        Take advantage of the latest technology innovations—AI, cloud native,
        IoT, microservices, and more. Rewrite mission-critical legacy systems as
        modern, multi component and microservice applications..
      </p>

      <h2 style={{ marginTop: "3em" }}>
        How to claim your risk free no obligation Low-Code proof of concept
      </h2>
      <p>
        Submit your details below and a member of our team will contact you to
        discuss your requirements.
      </p>
    </>
  )
}

const ContactLowCodePage = () => {
  return (
    <Layout title="Get your free Low-Code Proof of Concept">
      <Container
        as="section"
        style={{
          padding: "3em 0",
        }}
      >
        <ContactLowCodeContent />
        <ContactLowCodeForm />
      </Container>
    </Layout>
  )
}

ContactLowCodePage.defaultProps = {
  data: null,
}

ContactLowCodePage.propTypes = {
  data: PropTypes.shape({
    datoCmsHeroBanner: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
}

export default ContactLowCodePage
